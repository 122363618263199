.carousel-container {
  max-height: 550px;
}
.legend {
  height: 120px;
  padding: 1px !important;
  line-height: 1;
  z-index: 1;
}
.carousel-image {
  object-fit: fill;
}
.carousel-link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.carousel-root {
  padding-right: 30px;
}
